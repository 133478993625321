<template>
    <transition appear enter-active-class="animated fadeIn">
    <div>
        <div class="level-item">
            <h1 class="title is-1 page-title" style="padding-bottom:20px">Travel Insurance for the USA</h1>
        </div>
        <div class="box b-shdw-3">
            <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
            <div class="content">

                    <p class="paragraph-text">
                        There are so many amazing reasons to visit the USA. You might be looking forward to a big blow out in Las Vegas, dreaming of gazing at the landscapes of the Grand Canyon or saving for a shopping spree in New York.
                    </p>
                    <p class="paragraph-text">
                        Once you’ve booked your trip, it’s so important to get your travel insurance in place to protect yourself while you’re away.
                    </p>
                    <p class="paragraph-text">
                        We’ve put together a handy travel insurance buying guide for the USA, to help you choose a policy that’s right for you.
                    </p>
                    <p class="blue-text">Do I need travel insurance for the USA?</p>
                    <p class="paragraph-text">
                       Yes it’s essential to hold valid travel insurance when travelling to America. That’s because all medical treatment while in the country is private. Medical bills in the US are notoriously expensive, so the costs of an illness or accident while away could quickly add up. 
                    </p>
                    <p class="paragraph-text">
                       Not only that, but you also need to cover your flights in case of cancellation or delay, your luggage and your gadgets.
                    </p>
                    <p class="blue-text">What kind of insurance do I need when travelling in the USA?</p>
                    <p class="paragraph-text">
                        We provide a number of different policies, all of which are suitable for travel to the USA. Take a little look below at the various types of insurance:
                    </p>
                    <p class="paragraph-text">
                        <a href="single-trip-travel-insurance" target="_blank" class="text-link">Single Trip Insurance</a> – Ideal for a one-off trip to the USA.
                    </p>        
                    <p class="paragraph-text">
                        <a href="annual-trip-travel-insurance" target="_blank" class="text-link">Annual Multi-Trip Insurance</a> – If you’re planning a number of trips to the USA this year, or anywhere else in the world, it can be more economical to buy an Annual Multi-Trip policy.
                    </p>
                    <p class="paragraph-text">
                        <a href="longstay-travel-insurance" target="_blank" class="text-link">Longstay Travel Insurance</a> – For extended trips, longstay insurance can be more cost-effective, however bear in mind that you can only stay in the USA for up to 18 months.
                    </p>
                    <p class="paragraph-text">
                        <a href="cruise-cover" target="_blank" class="text-link">Cruise Cover</a> – Get cover tailored to your trip when you choose Cruise Cover. It offers additional policy features such as an increased cancellation cover and compensation for a missed port departure.
                    </p>
                    <p class="paragraph-text">
                        <a href="group-travel-insurance" target="_blank" class="text-link">Group Insurance</a> – When travelling in a group to the USA, take a look at group insurance and the benefits of all travelling on the same policy.
                    </p>
                    <p class="paragraph-text">
                        <a href="pre-existing-medical-conditions" target="_blank" class="text-link">Pre-Existing Medical Conditions Cover</a> – If you’re travelling with a pre-existing medical condition, use our online screening to ensure that your condition is covered.
                    </p>
                    <p class="paragraph-text">
                        <a href="over-65s-travel-insurance" target="_blank" class="text-link">Senior Travel Insurance</a> – Over 65s can enjoy specialist insurance, offering a range of additional benefits.
                    </p>
                    <p class="paragraph-text">
                        <a href="sports-activities-cover" target="_blank" class="text-link">Sports and Activities Cover</a> – Don’t forget to add Sports and Activities Cover to your policy if you’re planning to take part in any sports while travelling in the USA.
                    </p>
                    <p class="paragraph-text">
                        Deciding which policy is right for you can be tricky, and you want to make sure you have the appropriate level of cover. That’s why we’ve made our online quote system as simple as possible. You can compare policies side by side and find one that suits your needs and your budget.
                    </p>
                    <p class="blue-text">What does travel insurance for the USA cover?</p>
                    <p class="paragraph-text">
                        When shopping around for travel insurance policies, you’ll see a number of different features include:
                        <ul class="main-list-class extra-padding-top">
                            <li class="list-item extra-padding-bottom"><strong>Holiday Cancellation Cover and Curtailment Cover </strong>(if you need to cancel due to an unforeseen reason or cut short your trip)</li>
                            <li class="list-item extra-padding-bottom"><strong>Medical Expenses </strong>(to cover you for accident or illness while in the USA)</li>
                            <li class="list-item extra-padding-bottom"><strong>Gadget Cover </strong>(to protect any technology you might be travelling with, such as your smartphone, tablet or camera)</li>
                            <li class="list-item extra-padding-bottom"><strong>Loss of Luggage </strong>(to cover the total value of items in your suitcase)</li>
                            <li class="list-item extra-padding-bottom"><strong>Airline Failure </strong>(such as a delayed, missed or cancelled flight)</li>
                            <li class="list-item extra-padding-bottom"><strong>Personal Accident</strong></li>
                            <li class="list-item extra-padding-bottom"><strong>Mugging</strong>(in the unlikely incident of a mugging, your belongings will be covered)</li>
                            <li class="list-item extra-padding-bottom"><strong>Personal Liability </strong>(protection if you cause injury to another person or their property)</li>
                            <li class="list-item extra-padding-bottom"><strong>Theft or Loss of Cash</strong></li>
                        </ul>
                    </p>
                    <p class="paragraph-text">
                        To help you choose the right level of cover, start by making a list of the most valuable items you’re carrying with you. Also consider how much it would cost to replace every item in your suitcase if your luggage was to go missing. This can help you choose a policy more effectively. 
                    </p>
                    <p class="paragraph-text">
                        Sometimes a cheap policy can seem like a great deal, but you need to make sure you have the appropriate protection, both for your belongings and for yourself, while travelling in the USA.
                    </p>
                    <p class="blue-text">FAQs about Travel Insurance for the USA</p>
                    <p class="paragraph-text">
                        We know that travel insurance can sometimes be confusing! To help you choose the right insurance policy for your trip, we’ve compiled a number of frequently asked questions:
                    </p>    
                    <p class="blue-text">Do I need a visa for the USA?</p>
                    <p class="paragraph-text">
                        If you hold a UK passport, you don’t require a visa, however you do need to apply for an <a href="https://esta.cbp.dhs.gov/" target="_blank" class="text-link">ESTA (Electronic System for Travel Authorization)</a>. Once you have an ESTA you’re able to travel anywhere within the USA for up to 90 days. It costs $14 and you must have a copy with you when you travel. 
                    </p>   
                    <p class="blue-text">Why is USA travel insurance so expensive?</p>
                    <p class="paragraph-text">
                        Medical expenses in the USA are much higher than most places in the world, but you can still get a great deal. Other factors will also influence the cost of your policy, such as your age or if you have any pre-existing medical conditions.
                    </p>
                    <p class="blue-text">Where can I buy cheap travel insurance for the USA?</p>
                    <p class="paragraph-text">
                        Here at Covered2go we can provide you with a quick insurance quote for your trip to the USA. Get a quote online and you’ll be presented with a number of different policy options to choose from, tailored to your needs. Get in touch with a member of our friendly team if you have any questions.
                    </p>
                    <p class="blue-text">What is not covered by travel insurance?</p>
                    <p class="paragraph-text">
                        Look closely at your policy to check exactly what isn’t covered. Below is a list of things that are typically not included:
                    </p>
                    <p class="paragraph-text">
                        <strong>Medical Conditions</strong> – If you have a pre-existing condition make sure you declare it when you purchase your policy. If you don’t you won’t be covered if you were to fall ill while away.
                    </p>
                    <p class="paragraph-text">
                        <strong>Certain Sporting Activities</strong> – Be sure to check on your policy to see exactly what sporting activities are covered.
                    </p>
                    <p class="paragraph-text">
                        <strong>Substance Abuse</strong> – If an insurer considers that an injury or accident occurred due to the consumption of alcohol, they can refuse a claim. 
                    </p>
                    <p class="paragraph-text">
                    Here at Covered2go we make our policies clear, so you know exactly what is covered and what isn’t. <a href="get-quote" target="_blank" class="text-link">Get a quote online today!</a>
                    </p>
            </div>

<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
            <info-boxes/>
            <!-- <trustpilot-banner/> -->
            
            <div class="content mt-4">
                <p class="mb-1"><strong>*Please note:</strong></p>
                <p class="my-1">Available on Gold, Platinum and Cruise only.</p>
                <p class="my-1">Cover is provided so long as you did not cancel your trip because of FCDO, government or local authority advice relating to any infectious disease including Covid-19.</p>
                <p class="my-1">Terms and Conditions apply. Please see our <a >Policy Wording</a> for full details. </p>
            </div>
        </div>
    </div>
    </transition>
</template>
<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'UKTravelInsurance',
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	},
};
</script>

<style lang="scss" scoped>
@import 'src/styles/views/info-page';
</style>
